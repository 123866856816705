import React, { useState } from "react";
import classes from "./Services.module.css";
import "./Services.css";
import clsx from "clsx";

import {
  cloudSolutions,
  cryptoExchange,
  softwareDevelopement,
} from "../../../images";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Button, Heading, SectionHeading, Text } from "../../common";
import { FiPlus } from "react-icons/fi";
import { FaAngleLeft, FaAngleRight, FaArrowRight } from "react-icons/fa6";

const Services = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderData = [
    {
      img: cloudSolutions,
      title: "Cloud <br/> Solutions",
      info: "Tailored, scalable cloud services to enhance business efficiency and security.",
      button: "",
    },
    {
      img: softwareDevelopement,
      title: "Custom Software Development",

      info: "Bespoke software solutions designed for robust performance and scalability.",
      button: "",
    },
    {
      img: cryptoExchange,
      title: "Crypto Exchange Development",
      info: "Comprehensive, secure, and user-friendly cryptocurrency exchange platforms.",
      button: "",
    },
    {
      img: cryptoExchange,
      title: "Blockchain Software Solutions",
      info: "Secure, innovative blockchain software to revolutionize your digital transactions.",
      button: "",
    },
    {
      img: cloudSolutions,
      title: "Cloud <br/> Solutions",
      info: "Tailored, scalable cloud services to enhance business efficiency and security.",
      button: "",
    },
    {
      img: softwareDevelopement,
      title: "Custom Software Development",

      info: "Bespoke software solutions designed for robust performance and scalability.",
      button: "",
    },
    {
      img: cryptoExchange,
      title: "Crypto Exchange Development",
      info: "Comprehensive, secure, and user-friendly cryptocurrency exchange platforms.",
      button: "",
    },
    {
      img: cryptoExchange,
      title: "Blockchain Software Solutions",
      info: "Secure, innovative blockchain software to revolutionize your digital transactions.",
      button: "",
    },
  ];
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3.7,
    arrows: true,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow currentSlide={currentSlide} />,
    nextArrow: (
      <CustomNextArrow
        currentSlide={currentSlide}
        slideCount={sliderData.length}
      />
    ),
    beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3.8,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2.9,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2.2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1.9,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1.2,
        },
      },
    ],
  };

  return (
    <section className={classes.wrapper}>
      <div className={clsx(classes.container, "container")}>
        <div className={classes.header}>
          <div className={classes.headingContainer}>
            <SectionHeading heading="Services" />
            <Heading xl5 className={classes.heading}>
              Services to Drive Your Success
            </Heading>
          </div>
          <Text lg className={classes.info}>
            Explore our tailored tech and creative services at SolidHash, where
            we deliver innovative solutions with exceptional craftsmanship.
          </Text>
        </div>
        <div className={classes.services}>
          <Slider {...settings}>
            {sliderData.map((el, i) => (
              <div key={i}>
                <div className={classes.service}>
                  <img src={el.img} className={classes.serviceImg} alt="#" />
                  <div className={classes.titleContainer}>
                    <Heading lg font600 className={classes.title}>
                      <span
                        dangerouslySetInnerHTML={{ __html: el.title }}
                      ></span>
                    </Heading>

                    <button className={classes.addButton}>
                      <FiPlus className={classes.plusIcon} />
                    </button>
                  </div>
                  <Text base secondary>
                    {el.info}
                  </Text>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <Button transparent to="#" className={classes.seeAllProductsButton}>
          See all products <FaArrowRight />
        </Button>
      </div>
    </section>
  );
};

export default Services;
const CustomPrevArrow = ({ currentSlide, slideCount, onClick }) => {
  const isActive = currentSlide !== 0; // Check if current slide is not the first slide
  return (
    <button
      onClick={onClick}
      className={clsx(classes.previousButton, isActive && classes.activeButton)}
    >
      <FaAngleRight className={classes.arrow} />
    </button>
  );
};

const CustomNextArrow = ({ currentSlide, slideCount, onClick }) => {
  const isActive = currentSlide < slideCount - 1; // Check if current slide is not the last slide

  return (
    <button
      onClick={onClick}
      className={clsx(classes.nextButton, isActive && classes.activeButton)}
    >
      <FaAngleLeft className={classes.arrow} />
    </button>
  );
};
