import React from "react";
import classes from "./BlockChainServices.module.css";
import clsx from "clsx";
import { FaArrowRight } from "react-icons/fa6";
import { Button, Heading, Text } from "../../common";
import { blockChainServices, calenderIcon } from "../../../images";
import { FaCheckCircle } from "react-icons/fa";

const BlockChainServices = () => {
  return (
    <section className={classes.wrapper}>
      <div className={clsx("container", classes.container)}>
        <div className={classes.infoContainer}>
          <Text xl2 textWhite semiBold>
            Custom Blockchain Services
          </Text>
          <Heading xl4 font600 className={classes.heading}>
            Looking to launch your Layer 1 or Sidechain?
          </Heading>
          <div className={classes.listContainer}>
            {" "}
            <div className={classes.list}>
              <FaCheckCircle className={classes.checkMark} />
              <Text lg textWhite>
                Complete Ecosystem Development
              </Text>
            </div>{" "}
            <div className={classes.list}>
              <FaCheckCircle className={classes.checkMark} />
              <Text lg textWhite>
                Infrastructure Management
              </Text>
            </div>
          </div>
          <div className={classes.buttonContainer}>
            <Button onClick={() => {}} className={classes.button}>
              <img
                src={calenderIcon}
                alt="#"
                className={classes.calenderIcon}
              />
              Book a Meeting
            </Button>
            <Button transparent href="#" className={classes.button}>
              Whatsapp
              <FaArrowRight />
            </Button>
          </div>
        </div>
        <img src={blockChainServices} alt="#" className={classes.img} />
      </div>
    </section>
  );
};

export default BlockChainServices;
