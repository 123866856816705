import React, { useState, useEffect, useRef } from "react";
import { IoMdClose } from "react-icons/io";
import { AiOutlineAlignRight } from "react-icons/ai";
import classes from "./Navbar.module.css";

import clsx from "clsx";
import { calenderIcon, mainLogo } from "../../images";
import { Button } from "../common";
import { NavLink } from "react-router-dom";
import Products from "./Products/Products";

const Navbar = () => {
  const navItems = [
    { navItem: "Home", to: "/" },
    // { navItem: "About", to: "/about" },
    // { navItem: "Services", to: "/services" },
    { navItem: "Products", isProducts: true },
    { navItem: "Contact", to: "/Contact" },
    // { navItem: "Blog", to: "/blog" },
  ];
  const productsRef = useRef();
  const [showProducts, setShowProducts] = useState(false);
  const [sidebar, setSidebar] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const handleBookMetting = () => { };
  const handleScroll = () => {
    if (window.scrollY > 90) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div
      className={clsx(
        classes.wrapper,
        (isScrolled || sidebar) && classes.wrapperBg
      )}
    >
      <header className={clsx(classes.header, "container")}>
        <NavLink
          className={clsx(classes.navItem, classes.logoContainer)}
          to="/"
          onClick={() => setSidebar((prev) => !prev)}
        >
          <img src={mainLogo} alt="#" className={classes.logo} />
        </NavLink>

        <div
          className={clsx(
            classes.navItems,
            sidebar && classes.sidebar,
            "overflow"
          )}
        >
          {navItems.map((el, i) =>
            el.isProducts ? (
              <div
                ref={productsRef}
                className={classes.products}
                onClick={() => {
                  setShowProducts(true);
                }}
              >
                <p
                  className={clsx(
                    classes.navItem,
                    showProducts && classes.navActive
                  )}
                >
                  Products
                </p>
                <Products
                  isActive={showProducts}
                  setActive={setShowProducts}
                  productsRef={productsRef}
                />
              </div>
            ) : (
              <NavLink
                key={i}
                to={el.to}
                className={({ isActive }) =>
                  isActive
                    ? clsx(classes.navActive, classes.navItem)
                    : classes.navItem
                }
                onClick={() => setSidebar((prev) => !prev)}
              >
                {el.navItem}
              </NavLink>
            )
          )}
          <IoMdClose
            className={clsx(classes.icon, classes.closeIcon)}
            onClick={() => setSidebar((prev) => !prev)}
          />
          <Button
            onClick={() => {
              handleBookMetting();
            }}
            className={clsx(classes.mobileButton)}
          >
            <img src={calenderIcon} alt="#" className={classes.calenderIcon} />
            Book a Meeting
          </Button>
        </div>
        <div className={classes.buttonContainer}>
          <Button
            onClick={() => {
              handleBookMetting();
            }}
            className={classes.button}
          >
            <img src={calenderIcon} alt="#" className={classes.calenderIcon} />
            Book a Meeting
          </Button>
          {sidebar ? (
            <IoMdClose
              className={classes.icon}
              onClick={() => setSidebar((prev) => !prev)}
            />
          ) : (
            <AiOutlineAlignRight
              className={classes.icon}
              onClick={() => setSidebar((prev) => !prev)}
            />
          )}
        </div>
      </header>
    </div>
  );
};

export default Navbar;
