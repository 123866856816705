import React, { useState } from "react";

import classes from "./Footer.module.css";
import { Link } from "react-router-dom";
import { CiLinkedin } from "react-icons/ci";
import { PiGithubLogoBold } from "react-icons/pi";
import { RiTwitterXLine } from "react-icons/ri";
import { FaRegCalendarPlus, FaWhatsapp } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";

import clsx from "clsx";
import { prima, streams } from "../../images";
import { Heading, Text } from "../common";
import TermsAndConditions from "../../pages/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "../../pages/PrivacyPolicy/PrivacyPolicy";

const Footer = () => {
  const [showTermsAndConditions, setShowTermsAndConditons] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  return (
    <>
      <div className={clsx(classes.footer, "container")}>
        <footer className={classes.wrapper}>
          <div
            className={clsx(classes.itemContainer, classes.companyContainer)}
          >
            <Heading sm textWhite semiBold className={classes.heading}>
              Company
            </Heading>

            <Link to="/" className={classes.item}>
              Home
            </Link>
            <Link to="/about" className={classes.item}>
              About
            </Link>
            <button
              className={classes.item}
              onClick={() => setShowTermsAndConditons(true)}
            >
              Terms of Conditions
            </button>
            <button
              onClick={() => setShowPrivacyPolicy(true)}
              className={classes.item}
            >
              Privacy Policy
            </button>
          </div>{" "}
          <div className={clsx(classes.itemContainer, classes.products)}>
            <Heading sm textWhite semiBold className={classes.heading}>
              Products
            </Heading>
            <a
              href="#/"
              target="_blank"
              rel="noreferrer"
              className={classes.product}
            >
              <div className={classes.iconContainer}>
                <img src={prima} alt="#" className={classes.icon} />
              </div>
              <div className={classes.info}>
                <Heading textWhite base semiBold className={classes.heading}>
                  Prima
                </Heading>
                <Text className={classes.productInfo}>Crypto exchange API</Text>
              </div>
            </a>
            <a
              href="#/"
              target="_blank"
              rel="noreferrer"
              className={classes.product}
            >
              <div className={classes.iconContainer}>
                <img src={streams} alt="#" className={classes.icon} />
              </div>
              <div className={classes.info}>
                <Heading textWhite base semiBold className={classes.heading}>
                  Streams
                </Heading>
                <Text className={classes.productInfo}>
                  Blockchain EventsCrypto exchange API
                </Text>
              </div>
            </a>
          </div>{" "}
          <div className={clsx(classes.itemContainer, classes.talkToUs)}>
            <Heading sm textWhite semiBold className={classes.heading}>
              Talk to us
            </Heading>
            <a
              href="mailto:info@solidhash.io"
              rel="noreferrer"
              className={classes.item}
            >
              {" "}
              <div className={classes.socialIconContainer}>
                <MdOutlineEmail className={classes.socialIcon} />
              </div>
              info@solidhash.io
            </a>
            <a
              href="#/"
              target="_blank"
              rel="noreferrer"
              className={classes.item}
            >
              {" "}
              <div className={classes.socialIconContainer}>
                <FaRegCalendarPlus className={classes.socialIcon} />
              </div>{" "}
              Book a Meeting
            </a>{" "}
            <a
              href="#/"
              target="_blank"
              rel="noreferrer"
              className={classes.item}
            >
              <div className={classes.socialIconContainer}>
                <FaWhatsapp className={classes.socialIcon} />
              </div>{" "}
              Whatsapp
            </a>
          </div>
          <div className={clsx(classes.itemContainer, classes.socialContainer)}>
            <Heading sm textWhite semiBold className={classes.heading}>
              Social
            </Heading>
            <a
              href="#/"
              target="_blank"
              rel="noreferrer"
              className={classes.item}
            >
              {" "}
              <div className={classes.socialIconContainer}>
                <CiLinkedin className={classes.socialIcon} />
              </div>
              Linkedin
            </a>
            <a
              href="#/"
              target="_blank"
              rel="noreferrer"
              className={classes.item}
            >
              {" "}
              <div className={classes.socialIconContainer}>
                <PiGithubLogoBold className={classes.socialIcon} />
              </div>{" "}
              Github
            </a>{" "}
            <a
              href="#/"
              target="_blank"
              rel="noreferrer"
              className={classes.item}
            >
              <div className={classes.socialIconContainer}>
                <RiTwitterXLine className={classes.socialIcon} />
              </div>{" "}
              X (Twitter)
            </a>
          </div>
        </footer>
        <div className={classes.copyRightContainer}>
          <Text className={classes.copyRight}>
            © 2024 SolidHash. All rights reserved.
          </Text>
        </div>
      </div>
      {showTermsAndConditions && (
        <TermsAndConditions
          isActive={showTermsAndConditions}
          onClose={() => setShowTermsAndConditons(false)}
        />
      )}{" "}
      {showPrivacyPolicy && (
        <PrivacyPolicy
          isActive={showPrivacyPolicy}
          onClose={() => setShowPrivacyPolicy(false)}
        />
      )}
    </>
  );
};

export default Footer;
