import React from "react";
import classes from "./AutoSlider.module.css";
import Marquee from "react-fast-marquee";
import Text from "../Text/Text";
const AutoSlider = ({ items }) => {
  return (
    <Marquee autoFill={true} speed={40} pauseOnHover={true}>
      <div className={classes.items}>
        {items.map((item, i) => (
          <div className={classes.item} key={i}>
            <Text textPrimitive semiBold>
              {item}
            </Text>
          </div>
        ))}
      </div>
    </Marquee>
  );
};

export default AutoSlider;
