import React from "react";
import classes from "./GetStartInSecond.module.css";
import clsx from "clsx";
import {
  createAccount,
  defineParameter,
  startStreaming,
} from "../../../images";
import { Text, Heading } from "../../common";

const GetStartInSecond = () => {
  const steps = [
    {
      icon: createAccount,
      title: "Create Account",
      info: "Sign up quickly and securely to access our blockchain data indexing and serving service. Get started with just a few simple steps and unlock a world of decentralized data.",
    },
    {
      icon: defineParameter,
      title: "Define Parameters",
      info: "Set your data indexing preferences and customize your experience. Choose the parameters that best suit your needs and enjoy efficient data delivery.",
    },
    {
      icon: startStreaming,
      title: "Start Streaming",
      info: "Begin accessing and serving data from our reliable blockchain index. Experience seamless data retrieval and discover a new era of decentralized information.",
    },
  ];

  return (
    <section className={classes.wrapper} id="step">
      <div className={clsx("container", classes.container)}>
        <Heading xl5 font600 textCenter className={classes.heading}>
          Get Started in Seconds
        </Heading>
        <div className={classes.steps}>
          {steps.map((feature, id) => (
            <div className={classes.step} key={id}>
              <div className={classes.iconContainer}>
                <img src={feature.icon} alt="#" className={classes.icon} />
              </div>
              <Heading lg font600 className={classes.title}>
                {feature.title}
              </Heading>
              <Text base secondary>
                {feature.info}
              </Text>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default GetStartInSecond;
