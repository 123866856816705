import React from "react";
import clsx from "clsx";
import classes from "./Button.module.css";
import { Link } from "react-router-dom";
import { ImSpinner } from "react-icons/im";

const Button = ({
  children,
  onClick,
  href,
  transparent,
  transparent2,
  btnPrimary,
  btnInfo,
  infoTransparent,
  btnSuccess,
  wFull,
  className,
  to,
  loading,
  disabled,
  type,
  ...rest
}) => {
  return (
    <>
      {onClick ? (
        <button
          type={type}
          {...rest}
          className={clsx(
            className,
            classes.button,
            transparent && classes.transparent,
            transparent2 && classes.transparent2,
            btnPrimary && classes.btnPrimary,
            btnSuccess && classes.btnSuccess,
            wFull && classes.wFull,
            loading && classes.loading,
            disabled && classes.disabled,
            btnInfo && classes.btnInfo,
            infoTransparent && classes.infoTransparent
          )}
          onClick={disabled || loading ? undefined : onClick}
          disabled={loading}
        >
          {loading ? (
            <>
              <ImSpinner className={classes.spinner} /> Processing
            </>
          ) : (
            children
          )}
        </button>
      ) : href ? (
        <a
          {...rest}
          className={clsx(
            className,
            classes.button,
            transparent && classes.transparent,
            transparent2 && classes.transparent2,
            btnPrimary && classes.btnPrimary,
            btnSuccess && classes.btnSuccess,
            wFull && classes.wFull,
            loading && classes.loading,
            disabled && classes.disabled,
            btnInfo && classes.btnInfo,
            infoTransparent && classes.infoTransparent
          )}
          href={href}
          target="_blank"
          rel="noreferrer"
        >
          {loading ? (
            <>
              <ImSpinner className={classes.spinner} /> Processing
            </>
          ) : (
            children
          )}
        </a>
      ) : to ? (
        <Link
          {...rest}
          className={clsx(
            className,
            classes.button,
            transparent && classes.transparent,
            transparent2 && classes.transparent2,
            btnPrimary && classes.btnPrimary,
            btnSuccess && classes.btnSuccess,
            wFull && classes.wFull,
            loading && classes.loading,
            disabled && classes.disabled,
            btnInfo && classes.btnInfo,
            infoTransparent && classes.infoTransparent
          )}
          to={to}
        >
          {loading ? (
            <>
              <ImSpinner className={classes.spinner} /> Processing
            </>
          ) : (
            children
          )}
        </Link>
      ) : (
        <button
          {...rest}
          className={clsx(
            className,
            classes.button,
            transparent && classes.transparent,
            transparent2 && classes.transparent2,
            btnPrimary && classes.btnPrimary,
            btnSuccess && classes.btnSuccess,
            wFull && classes.wFull,
            loading && classes.loading,
            disabled && classes.disabled,
            btnInfo && classes.btnInfo,
            infoTransparent && classes.infoTransparent
          )}
        >
          {loading ? (
            <>
              <ImSpinner className={classes.spinner} /> Processing
            </>
          ) : (
            children
          )}
        </button>
      )}
    </>
  );
};

export default Button;
