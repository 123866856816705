import React from "react";
import Modal from "../../components/common/Modal/Modal";
import classes from "./PrivacyPolicy.module.css";
import { Heading, Text } from "../../components/common";
import clsx from "clsx";
import { MdClose } from "react-icons/md";

const PrivacyPolicy = ({ isActive, onClose }) => {
  const privacyPolicies = [
    {
      title: "Condition Title Here",
      description:
        "The first step in creating an AI roadmap is to identify opportunities for AI implementation within your organization. This involves conducting a thorough analysis of your business processes, identifying areas where AI can drive the most value, and prioritizing initiatives based on their potential impact and feasibility.",
    },
    {
      title: "Condition Title Here",
      description:
        "Before embarking on an AI implementation, it’s essential to assess your organization’s readiness. This includes evaluating your technical infrastructure, data quality and availability, and the skills and expertise of your team. By understanding your current capabilities and gaps, you can identify the resources and investments needed to support your AI initiatives.",
    },
    {
      title: "Condition Title Here",
      description:
        "The first step in creating an AI roadmap is to identify opportunities for AI implementation within your organization. This involves conducting a thorough analysis of your business processes, identifying areas where AI can drive the most value, and prioritizing initiatives based on their potential impact and feasibility.",
    },
    {
      title: "Condition Title Here",
      description:
        "Before embarking on an AI implementation, it’s essential to assess your organization’s readiness. This includes evaluating your technical infrastructure, data quality and availability, and the skills and expertise of your team. By understanding your current capabilities and gaps, you can identify the resources and investments needed to support your AI initiatives.",
    },
    {
      title: "Condition Title Here",
      description:
        "The first step in creating an AI roadmap is to identify opportunities for AI implementation within your organization. This involves conducting a thorough analysis of your business processes, identifying areas where AI can drive the most value, and prioritizing initiatives based on their potential impact and feasibility.",
    },
    {
      title: "Condition Title Here",
      description:
        "Before embarking on an AI implementation, it’s essential to assess your organization’s readiness. This includes evaluating your technical infrastructure, data quality and availability, and the skills and expertise of your team. By understanding your current capabilities and gaps, you can identify the resources and investments needed to support your AI initiatives.",
    },
    {
      title: "Condition Title Here",
      description:
        "The first step in creating an AI roadmap is to identify opportunities for AI implementation within your organization. This involves conducting a thorough analysis of your business processes, identifying areas where AI can drive the most value, and prioritizing initiatives based on their potential impact and feasibility.",
    },
    {
      title: "Condition Title Here",
      description:
        "Before embarking on an AI implementation, it’s essential to assess your organization’s readiness. This includes evaluating your technical infrastructure, data quality and availability, and the skills and expertise of your team. By understanding your current capabilities and gaps, you can identify the resources and investments needed to support your AI initiatives.",
    },
    {
      title: "Condition Title Here",
      description:
        "The first step in creating an AI roadmap is to identify opportunities for AI implementation within your organization. This involves conducting a thorough analysis of your business processes, identifying areas where AI can drive the most value, and prioritizing initiatives based on their potential impact and feasibility.",
    },
    {
      title: "Condition Title Here",
      description:
        "Before embarking on an AI implementation, it’s essential to assess your organization’s readiness. This includes evaluating your technical infrastructure, data quality and availability, and the skills and expertise of your team. By understanding your current capabilities and gaps, you can identify the resources and investments needed to support your AI initiatives.",
    },
  ];
  return (
    <Modal className={classes.modal} isActive={isActive} onClose={onClose}>
      <div className={clsx(classes.wrapper, "overflow")}>
        <button className={classes.closeContainer} onClick={onClose}>
          <MdClose className={classes.closeIcon} />
        </button>
        <div className={classes.container}>
          <div className={classes.header}>
            <Heading xl4 font600>
              Privacy Policy
            </Heading>
            <Text lg className={classes.info}>
              Artificial intelligence (AI) has the potential to transform
              businesses across industries, but implementing AI can be a complex
              and challenging process. One of the key steps in ensuring a
              successful AI adoption is creating a comprehensive AI roadmap. In
              this post, we’ll provide a step-by-step guide to AI road mapping,
              helping you plan your AI implementation and achieve your goals.
            </Text>
          </div>
          {privacyPolicies.map((el, i) => (
            <div className={classes.termsAndConditon}>
              <Heading xl semiBold className={classes.title}>
                <span>{i + 1}.</span> {el.title}
              </Heading>
              <Text lg className={classes.description}>
                {el.description}
              </Text>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default PrivacyPolicy;
