import React from "react";
import classes from "./AboutUs.module.css";
import clsx from "clsx";

import { Text, Heading, Button } from "../../common";
import { aboutImg } from "../../../images";

import { FaArrowRight } from "react-icons/fa6";

const AboutUs = () => {
  return (
    <section className={classes.wrapper} id="about">
      <div className={clsx("container", classes.container)}>
        <div className={classes.headingContainer}>
          {" "}
          <Heading xl5 textCenter>
            Welcome to Solidhash
          </Heading>
          <Text textWhite lg textCenter>
            Our commitment to excellence ensures every project exceeds
            expectations, driving success and innovation.
          </Text>
        </div>
        <div className={classes.aboutUs}>
          <img src={aboutImg} alt="#" className={classes.img} />

          <div className={classes.infoContainer}>
            <div className={classes.header}>
              <Heading xl3 font600>
                Where Vision Meets Precision
              </Heading>
            </div>
            <Text primary lg>
              At SolidHash, we're unlocking innovation and crafting excellence
              in the blockchain arena. As a leading blockchain development firm,
              we blend technical prowess with creativity, delivering bespoke
              solutions that transform complexities into elegant outcomes.
              Committed to excellence and innovation, we empower our clients by
              turning ambitious visions into reality. Join us at the forefront
              of blockchain technology, where code meets creativity.
            </Text>{" "}
            <Text primary lg>
              Our team of experts is dedicated to staying at the cutting edge of
              blockchain technology, ensuring that every project we undertake is
              executed with the utmost quality and innovation
            </Text>
            <Button transparent2 onClick={() => {}} className={classes.button}>
              Get Started <FaArrowRight />
            </Button>{" "}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
