import React from "react";
import classes from "./Web3.module.css";
import clsx from "clsx";
import { web3Logo } from "../../../images";
import { Button, Heading, Text } from "../../common";
import Counter from "../../common/Counter/Counter";
import { TiPlus } from "react-icons/ti";
import { FaArrowRight } from "react-icons/fa6";
import AutoSlider from "../../common/AutoSlider/AutoSlider";

const Web3 = () => {
  const web3s = [
    "NFTs",
    "Gaming",
    "DAOs",
    "DeFi",
    "NFTs",
    "Smart Contracts",
    "Decentralized Storage",
    "Identity Management",
    "Decentralized Exchanges",
    "Yield Farming",
    "Staking",
    "Social Tokens",
    "Oracles",
    "Layer 2 Solutions",
    "Decentralized Marketplaces",
    "Crypto Wallets",
    "Blockchain Analytics",
    "Tokenized Assets",
    "Metaverse",
    "Cross-Chain Protocols"
  ];

  return (
    <section className={classes.wrapper}>
      <div className={clsx("container", classes.container)}>
        <div className={classes.infoWrapper}>
          <div className={classes.headingContainer}>
            <Heading className={classes.heading}>Web 3.0</Heading>
            <Text textWhite xl className={classes.tagline}>
              Powered by - SolidHash
            </Text>
          </div>
          <Text lg primary className={classes.info}>
            Welcome to the future of the web with SolidHash. We are pioneering the next generation of internet technologies, ensuring a decentralized, secure, and user-centric experience for everyone.


          </Text>{" "}
          <Button className={classes.button}>
            Get Started <FaArrowRight />
          </Button>{" "}
        </div>
        <div className={classes.features}>
          <div className={classes.feature}>
            <Heading xl2 font600>
              <Counter start={0} end={200} durationTime={20} />
              <TiPlus className={classes.plusIcon} /> <br />
              Nodes{" "}
            </Heading>
            <Text base secondary>
              Our infrastructure includes over 200 nodes, ensuring high availability and reliability for decentralized applications.

            </Text>
          </div>
          <div className={classes.feature}>
            <Heading xl2 font600>
              <Counter start={0} end={12.5} durationTime={60} /> Billion
              <TiPlus className={classes.plusIcon} /> <br />
              Queries
            </Heading>
            <Text base secondary>
              Our platform supports large-scale operations with over 12.5 billion queries processed, providing swift and efficient data handling.




            </Text>
          </div>{" "}
          <div className={classes.feature}>
            <Heading xl2 font600>
              <Counter start={0} end={100} durationTime={20} />
              {/* K */}
              <TiPlus className={classes.plusIcon} /> <br />
              Projects
            </Heading>
            <Text base secondary>
              SolidHash drives innovation with over 100 projects leveraging our technology, promoting the growth and adoption of Web 3.0 solutions.
            </Text>
          </div>
        </div>{" "}
        <div className={classes.sliderContainer}>
          <AutoSlider items={web3s} />
        </div>
      </div>
    </section>
  );
};

export default Web3;
