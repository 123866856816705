import React from "react";
import classes from "./HeroSection.module.css";
import clsx from "clsx";

import { Text, Heading, Button } from "../../common";
import { calenderIcon, leftArt, rightArt } from "../../../images";
import { FaArrowRight } from "react-icons/fa6";

import { IoIosArrowRoundDown } from "react-icons/io";

import { Link } from "react-scroll";

const HeroSection = () => {
  return (
    <section className={clsx(classes.wrapper)} id="home">
      <div className={clsx("container", classes.container)}>
        <img src={leftArt} alt="#" className={classes.leftArt} />
        <div className={classes.infoContainer}>
          <Heading xl6 textCenter ck>
            Let’s create your next tech innovation
          </Heading>
          <Text textWhite lg textCenter semiBold>
            At SolidHash, we push the boundaries of technology to deliver
            innovative solutions and unparalleled craftsmanship.
          </Text>
        </div>{" "}
        <div className={classes.buttonContainer}>
          <Button onClick={() => {
            let api = "https://calendly.com/solidhash/30min";
            window.open(api, "_blank");
          }}>
            <img src={calenderIcon} alt="#" className={classes.calenderIcon} />{" "}
            Book a Meeting
          </Button>{" "}
          <Button transparent href="#">
            Whatsapp
            <FaArrowRight />
          </Button>
        </div>
        <Link
          className={classes.link}
          to="features"
          activeClass={classes.navActive}
          spy={true}
          smooth={true}
          offset={-75}
          duration={1000}
        >
          <Text textWhite base textCenter>
            Scroll to see more
          </Text>
          <IoIosArrowRoundDown className={classes.downArrow} />
        </Link>
        <img src={rightArt} alt="#" className={classes.rightArt} />
      </div>
    </section>
  );
};

export default HeroSection;
