import React from "react";
import classes from "./AccessBlockchain.module.css";
import clsx from "clsx";
import {
  arbitrum,
  avalanche,
  base,
  blast,
  bnbChain,
  celo,
  ethereum,
  optimism,
  polygon,
} from "../../../images";
import { Heading, Text } from "../../common";
import Counter from "../../common/Counter/Counter";
import { TiPlus } from "react-icons/ti";

import AutoSlider from "./AutoSlider/AutoSlider";

const AccessBlockchain = () => {
  const web3s = [
    arbitrum,
    avalanche,
    base,
    blast,
    bnbChain,
    celo,
    ethereum,
    optimism,
    polygon,
  ];
  return (
    <section className={classes.wrapper}>
      <div className={clsx("container", classes.container)}>
        <Heading className={classes.heading} textCenter>
          Access 20+ Blockchains
        </Heading>
        <div className={classes.features}>
          <div className={classes.feature}>
            <Heading xl4 base semiBold className={classes.title}>
              <Counter start={0} end={36} durationTime={20} />
              ms
            </Heading>
            <Text base secondary className={classes.info}>
              Avg. response time
            </Text>
          </div>
          <div className={classes.feature}>
            <Heading xl4 base semiBold className={classes.title}>
              <Counter start={0} end={99.99} durationTime={20} />%
            </Heading>
            <Text base secondary className={classes.info}>
              Uptime
            </Text>
          </div>{" "}
          <div className={classes.feature}>
            <Heading xl4 base semiBold className={classes.title}>
              <Counter start={0} end={30} durationTime={20} />
              <TiPlus className={classes.plusIcon} />
            </Heading>
            <Text base secondary className={classes.info}>
              Blockchains
            </Text>
          </div>
        </div>{" "}
        <div className={classes.sliderContainer}>
          <AutoSlider items={web3s} />
        </div>
      </div>
    </section>
  );
};

export default AccessBlockchain;
