import React from "react";
import classes from "./ThreatDetection.module.css";
import clsx from "clsx";
import { FaArrowRight } from "react-icons/fa6";
import { Button, Heading, Text } from "../../common";
import { calenderIcon, threatDetection } from "../../../images";

const ThreatDetection = () => {
  return (
    <section className={classes.wrapper}>
      <div className={clsx("container", classes.container)}>
        <div className={classes.infoContainer}>
          <Heading xl3 font600 className={classes.heading}>
            Real Time Threat Detection
          </Heading>
          <Text base textWhite>
            Configure our system to instantly notify you of any malicious
            activities targeting your contract. Our alert mechanism is designed
            to recognize and respond to suspicious transactions in real-time,
            ensuring you're always informed and prepared to take immediate
            action. This feature empowers you to maintain the integrity and
            security of your blockchain operations with minimal effort.
          </Text>
          <div className={classes.buttonContainer}>
            <Button onClick={() => {}} className={classes.button}>
              <img
                src={calenderIcon}
                alt="#"
                className={classes.calenderIcon}
              />
              Book a Meeting
            </Button>
            <Button transparent href="#" className={classes.button}>
              Whatsapp
              <FaArrowRight />
            </Button>
          </div>
        </div>
        <img src={threatDetection} alt="#" className={classes.img} />
      </div>
    </section>
  );
};

export default ThreatDetection;
