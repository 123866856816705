import React from "react";
import classes from "./AutoSlider.module.css";
import Marquee from "react-fast-marquee";

const AutoSlider = ({ items }) => {
  return (
    <Marquee autoFill={true} speed={40} pauseOnHover={true}>
      <div className={classes.items}>
        {items.map((img, i) => (
          <div className={classes.item} key={i}>
            <img src={img} alt="#" className={classes.img} />
          </div>
        ))}
      </div>
    </Marquee>
  );
};

export default AutoSlider;
