import React from "react";
import classes from "./Features.module.css";
import clsx from "clsx";
import {
  deliverResult,
  digitalPresence,
  empowering,
  futureTechnologies,
} from "../../../images";
import { Text, Heading } from "../../common";

const Features = () => {
  const features = [
    {
      icon: empowering,
      title: "Empowering  Ideas",
      info: "We turn your vision into digital reality.",
    },

    {
      icon: digitalPresence,
      title: "Crafting Digital Presence",
      info: "Elevate your online presence with our expertise.",
    },
    {
      icon: futureTechnologies,
      title: "Pioneering Future Technologies",
      info: "Elevate your online presence with our expertise.",
    },
    {
      icon: deliverResult,
      title: "Beyond Development, We Deliver Results.",
      info: "Your success, our commitment – always.",
    },
  ];
  return (
    <section className={classes.wrapper} id="features">
      <div className={clsx("container", classes.container)}>
        <Text semiBold textCenter>
          Store, Decode and Consume Blockchain Data at scale
        </Text>
        <div className={classes.features}>
          {features.map((feature, id) => (
            <div className={classes.feature} key={id}>
              <img src={feature.icon} alt="#" className={classes.icon} />
              <Heading lg font600 className={classes.heading}>
                {feature.title}
              </Heading>
              <Text base secondary>
                {feature.info}
              </Text>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;
