import React from "react";
import { logo } from "../../../images";
import classes from "./SectionHeading.module.css";
import Heading from "../Heading/Heading";

const SectionHeading = ({ heading }) => {
  return (
    <div className={classes.sectionHeading}>
      <img src={logo} alt="#" className={classes.logo} />
      <Heading lg primaryDefault semiBold>
        {heading}
      </Heading>
    </div>
  );
};

export default SectionHeading;
