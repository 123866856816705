import React from "react";
import classes from "./Products.module.css";
import clsx from "clsx";
import { Button, Heading, Text } from "../../common";
import { prima, streams } from "../../../images";
import useOnClickOutside from "../../../hooks";

const Products = ({ isActive, setActive, productsRef }) => {
  const products = [
    { img: prima, name: "Prima", info: "Whitelabel Crypto exchange" },
    {
      img: streams,
      name: "Streams",
      info: "Blockchain Events",
    },
  ];

  useOnClickOutside(productsRef, () => setActive(false));
  return (
    <div
      className={clsx(
        classes.itemContainer,
        classes.products,
        isActive && classes.active
      )}
    >
      {products.map((product, i) => (
        <a
          href="#/"
          target="_blank"
          rel="noreferrer"
          className={classes.product}
        >
          <div className={classes.iconContainer}>
            <img src={product.img} alt="#" className={classes.icon} />
          </div>
          <div className={classes.info}>
            <Heading textWhite base semiBold className={classes.heading}>
              {product.name}
            </Heading>
            <Text className={classes.productInfo}>{product.info}</Text>
          </div>
        </a>
      ))}

      <Button wFull onClick={() => { }} className={classes.button}>
        Get Started
      </Button>
    </div>
  );
};

export default Products;
