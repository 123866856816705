import React from "react";
import classes from "./HelperTools.module.css";
import clsx from "clsx";
import {
  deliverResult,
  digitalPresence,
  empowering,
  futureTechnologies,
} from "../../../images";
import { Text, Heading } from "../../common";

const HelperTools = () => {
  const features = [
    {
      title: "User-Friendly ABI Decoder",
      info: "Gain clear insights into blockchain events with just the ABI and log data. Our intuitive decoder transforms this information into an easily understandable format, delivering the insights you need without any coding required!",
    },

    {
      title: "Smart Contract Query Interface",
      info: "Introducing our advanced solution, purpose-built to streamline the querying of smart contracts, treating them with the simplicity and directness of traditional API interactions. This professional-grade solution enhances operational efficiency by providing effortless access to smart contract functionalities, making complex blockchain interactions straightforward and user-friendly.",
    },
    {
      title: "Streams API",
      info: "Our Streams API mirrors the functionality of traditional log data analysis but is specifically engineered for transfer transactions across a diverse array of blockchain networks, including EVM chains, Bitcoin, Solana, and Tron. This broad compatibility ensures comprehensive monitoring and analysis capabilities, no matter the blockchain infrastructure you're utilizing.",
    },
    {
      title: "Smart Contract Automation",
      info: "Our tool enables the automated execution of smart contracts, either on a predetermined schedule or triggered by specific events, enhancing operational efficiency and responsiveness.",
    },
  ];
  const getTopMargin = (index) => {
    return { top: `${100 + index * 10}px` };
  };
  return (
    <section className={classes.wrapper} id="features">
      <div className={clsx("container", classes.container)}>
        <Heading xl5 font600 className={classes.heading} textCenter>
          Fleet of Helper Tools to make your Day
        </Heading>
        <div className={classes.features}>
          {features.map((feature, id) => (
            <div className={classes.feature} key={id} style={getTopMargin(id)}>
              <Heading xl2 font600 className={classes.title}>
                {feature.title}
              </Heading>
              <Text lg secondary className={classes.info}>
                {feature.info}
              </Text>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HelperTools;
