import React from "react";
import classes from "./Home.module.css";
import HeroSection from "../../components/Home/HeroSection/HeroSection";
import Features from "../../components/Home/Features/Features";
import AboutUs from "../../components/Home/AboutUs/AboutUs";
import Services from "../../components/Home/Services/Services";
import GetStarted from "../../components/Home/GetStarted/GetStarted";
import Web3 from "../../components/Home/Web3.0/Web3";
import LogDog from "../../components/Home/LogDog/LogDog";
import ThreatDetection from "../../components/Home/ThreatDetection/ThreatDetection";
import GetStartInSecond from "../../components/Home/GetStartInSecond/GetStartInSecond";
import CryptoExchage from "../../components/Home/CryptoExchage/CryptoExchage";
import BlockChainServices from "../../components/Home/BlockChainServices/BlockChainServices";
import HelperTools from "../../components/Home/HelperTools/HelperTools";
import SolidashPlayground from "../../components/Home/SolidashPlayground/SolidashPlayground";
import AccessBlockchain from "../../components/Home/AccessBlockchain/AccessBlockchain";
import StreamsApi from "../../components/Home/StreamsApi/StreamsApi";
import StartWithPrima from "../../components/Home/StartWithPrima/StartWithPrima";
import Footer from "../../components/Footer/Footer";

const Home = () => {
  return (
    <div className={classes.wrapper}>
      <HeroSection />
      <Features />
      <AboutUs />
      <Services />
      {/* <GetStarted /> */}
      <Web3 />
      {/* <LogDog /> */}
      <ThreatDetection />
      <GetStartInSecond />
      <HelperTools />
      {/* <SolidashPlayground /> */}
      {/* <CryptoExchage /> */}
      <BlockChainServices />
      <AccessBlockchain />
      {/* <StreamsApi /> */}
      {/* <StartWithPrima /> */}
      {/* <Footer /> */}

      {/* <></> */}
    </div>
  );
};

export default Home;
